globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"e6fe4b5625e3043bb5397c8851ed045f932768df"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

Sentry.init({
   enabled: getClientPublicRuntimeConfig().sentryEnv !== 'development',
   dsn:
      getClientPublicRuntimeConfig().sentryDsn ??
      'https://a4e528eaecd54c96b5d67dd7f19495a7@o4503938252275712.ingest.sentry.io/4503938260598784',
   // Adjust this value in production, or use tracesSampler for greater control
   tracesSampleRate: 1.0,
   environment: getClientPublicRuntimeConfig().sentryEnv,

   // This sets the sample rate to be 10%. You may want this to be 100% while
   // in development and sample at a lower rate in production
   replaysSessionSampleRate: 0.1,

   // If the entire session is not sampled, use the below sample rate to sample
   // sessions when an error occurs.
   replaysOnErrorSampleRate: 1.0,
   integrations: [
      Sentry.replayIntegration({
         // Additional SDK configuration goes in here, for example:
         maskAllText: true,
         blockAllMedia: true,
      }),
   ],
   beforeSend(event) {
      // Modify the event here
      if (event.user) {
         // Don't send user's ip address
         delete event.user.ip_address;
      }
      return event;
   },

   ignoreErrors: [
      // add ignore errors here
   ],
   denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
   ],
});
